import styles from '@/components/home/product-section/ProductSection.module.css'
import { motion } from 'framer-motion'
import ButtonComponent from '@/components/ui/buttons/ButtonComponent'
import StatusIndicator from '@/components/ui/status-indicator/StatusIndicator'
import EVHealthCheckerPreview from '@/components/home/product-section/product-previews/EVHealthCheckerPreview'
import UsedEVLookupPreview from '@/components/home/product-section/product-previews/UsedEVLookupPreview'
import EVExplorerPreview from '@/components/home/product-section/product-previews/EVExplorerPreview'
import EVKnowledgeHubPreview from '@/components/home/product-section/product-previews/EVKnowledgeHubPreview'
import EVResaleReportsPreview from '@/components/home/product-section/product-previews/UsedEVLookupPreview'
import FlashBatteryChecksPreview from '@/components/home/product-section/product-previews/EVHealthCheckerPreview'
import IndependentBatteryChecksPreview from '@/components/home/product-section/product-previews/EVHealthCheckerPreview'
import EVAPIPreview from '@/components/home/product-section/product-previews/EVAPIDataPreview'
import { useRouter } from 'next/router'
import { useState, useEffect, useRef } from 'react'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import AppStoreButtons from '@/components/ui/buttons/AppStoreButtons'

const slideFromLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.7 },
  },
}

const slideFromRight = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.7 },
  },
}

const products = {
  consumers: [
    {
      id: 'ev-health-checker',
      title: 'EV Health Checker App',
      description: 'Test the battery health of any EV with our easy-to-use iOS or Android app.',
      buttonText: 'Download app',
      route: '/ev-health-checker',
      component: EVHealthCheckerPreview,
    },
    // {
    //   id: 'used-ev-lookup',
    //   title: 'Used EV Lookup',
    //   description: 'Access specific comprehensive electric vehicle data for any used electric vehicle in one click.',
    //   buttonText: 'Lookup a used EV',
    //   route: '/lite',
    //   component: UsedEVLookupPreview,
    // },
    {
      id: 'ev-explorer',
      title: 'EV Explorer',
      description: 'Get under the skin of any EV using our comprehensive EV Directory.',
      buttonText: 'Search for an EV',
      route: '/directory',
      component: EVExplorerPreview,
    },
    {
      id: 'ev-knowledge-hub',
      title: 'EV Knowledge Hub',
      description: 'Learn about going electric with free access to our entire catalogue of support articles.',
      buttonText: 'Explore the archives',
      route: '/knowledge',
      component: EVKnowledgeHubPreview,
    },
  ],
  businesses: [
    {
      id: 'ev-resale-reports',
      title: 'EV Resale Reports',
      description:
        'Generate EV Resale Reports for your forecourt, website or customers from a reg and odometer reading. Proven to shift retail stock 2.5x faster.',
      buttonText: 'Try it out',
      route: '/lite',
      component: EVResaleReportsPreview,
    },
    {
      id: 'flash-battery-checks',
      title: 'Flash Battery Health Checks',
      description: 'Upgrade your EV Resale Reports with OEM battery health data.',
      buttonText: 'Enquire now',
      route: 'https://form.typeform.com/to/NDO6y50K',
      component: FlashBatteryChecksPreview,
    },
    {
      id: 'independent-battery-checks',
      title: 'Independent Battery Health Checks',
      description:
        'Use existing telematics, APIs, or our patent-pending mobile solution to provide an independent battery health deep dive on fleets or specific EVs.',
      buttonText: 'Enquire now',
      route: 'https://form.typeform.com/to/NDO6y50K',
      component: IndependentBatteryChecksPreview,
    },
    {
      id: 'ev-api-data',
      title: 'EV API Data',
      description:
        'Integrate our EV data into your website and platform. From real world range to charging, we’ve got the industry’s most comprehensive EV data.',
      buttonText: 'Access our API',
      route: 'https://form.typeform.com/to/NDO6y50K',
      component: EVAPIPreview,
    },
  ],
}

const ProductSection = () => {
  const router = useRouter()
  const [activeSection, setActiveSection] = useState('consumers') // "consumers" or "businesses"
  const [activeProductId, setActiveProductId] = useState(products.consumers[0].id)
  const [inView, setInView] = useState(false) // Track visibility
  const sectionRef = useRef(null)

  // Create a ref for the title element
  const titleRef = useRef(null)

  // Define the text you want to type out
  const messages = ['Our Products']

  // This hook expects arrays for refs and messages,
  // so even if it's a single element, we pass them in arrays.
  const refs = [titleRef]

  // Trigger animations when section enters the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setInView(true)
      },
      { threshold: 0.5 }, // Trigger when 30% of the section is visible
    )
    if (sectionRef.current) observer.observe(sectionRef.current)
    return () => observer.disconnect()
  }, [])

  const handleSectionToggle = (section) => {
    if (section !== activeSection) {
      setActiveSection(section)
      setActiveProductId(products[section][0].id)
    }
  }

  const handleProductClick = (productId) => {
    if (productId !== activeProductId) {
      setActiveProductId(productId)
    }
  }

  const currentProducts = products[activeSection]
  const activeProduct = currentProducts.find((product) => product.id === activeProductId)

  const openSampleReport = () => {
    // navigate to clearwatt.co.uk/check/l3
    window.open('https://clearwatt.co.uk/check/l3', '_blank')
    return null
  }

  const renderProductList = (section) => {
    const isActive = activeSection === section

    return (
      <div
        className={`${styles.tabContainer} ${isActive ? styles.activeTabContainer : styles.inactiveTabContainer}`}
        onClick={() => handleSectionToggle(section)}
      >
        <div className={styles.tabHeader}>
          {/* Add StatusIndicator here */}
          <span className={styles.tabTitle}>{section === 'consumers' ? 'For Consumers' : 'For Businesses'}</span>
          <StatusIndicator status={isActive ? 'green' : 'grey'} size={'1.7rem'} />
        </div>
        {isActive && (
          <div className={styles.productList}>
            {currentProducts.map((product) => {
              const isActiveProduct = product.id === activeProductId
              return (
                <div
                  key={product.id}
                  className={isActiveProduct ? styles.productItemActive : styles.productItemInactive}
                  onClick={() => handleProductClick(product.id)}
                >
                  <div className={styles.productTitleWrapper}>
                    <span className={styles.productTitle}>{product.title}</span>
                    <StatusIndicator status={isActiveProduct ? 'green' : 'grey'} size={'1.7rem'} />
                  </div>

                  {isActiveProduct && (
                    <div className={styles.productContent}>
                      <div className={styles.productDescription}>{product.description}</div>

                      {product.id === 'ev-health-checker' ? (
                        <div className={styles.productPricing}>
                          {/* Pricing Information */}
                          <div className={styles.productOfferText}>LIMITED OFFER ON FULL REPORT</div>
                          <div className={styles.productOldPrice}>£39.99</div>
                          <div className={styles.productNewPrice}>£24.99</div>
                          <div className={styles.discount}>
                            Use code <span className={styles.discountCode}>15OFF</span> at checkout
                          </div>
                          <ButtonComponent
                            variant="default"
                            text={'View sample report'}
                            onClick={openSampleReport}
                            className={styles.sampleReportButton}
                          />
                          {/* App Store Buttons */}
                          <AppStoreButtons theme="light" />
                        </div>
                      ) : (
                        <ButtonComponent
                          text={product.buttonText}
                          onClick={() => router.push(product.route)}
                          variant="default"
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  const ActivePreviewComponent = activeProduct?.component || null
  const delays = [500, 900]

  useTypewriterOnScroll(refs, messages, styles, delays)

  return (
    <div className={styles.container} ref={sectionRef}>
      <div className={styles.content}>
        <div className={styles.sectionIntroduction}>
          <h3 ref={titleRef} className={styles.h3}></h3>
        </div>
        <div className={styles.sectionContent}>
          {/* Product Selector */}
          <motion.div
            className={styles.productSelector}
            variants={slideFromLeft}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            {renderProductList('consumers')}
            {renderProductList('businesses')}
          </motion.div>

          {/* Product Preview */}
          <motion.div
            className={styles.productPreview}
            variants={slideFromRight}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            {ActivePreviewComponent ? <ActivePreviewComponent /> : <div className={styles.placeholder}></div>}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default ProductSection
