import styles from '@/components/home/product-section/product-previews/EVExplorerPreview.module.css'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import useRevealOnScroll from '@/utils/useRevealOnScroll'
import SearchComponentXS from '@/components/ui/search/SearchComponentXS'
import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useRouter } from 'next/router'
import { authenticatedFetchAPI } from '@/pages/api/clearwattApi'
import Image from 'next/image'
import { FaArrowRight } from 'react-icons/fa'
import Link from 'next/link'

const EVExplorerPreview = () => {
  const router = useRouter()

  const [badges, setBadges] = useState([])
  const [searchTerm, setSearchTerm] = useState({ term: '', type: '' })
  const [model, setModel] = useState(null)

  const titleRef = useRef(null)
  const title = 'EV Directory'
  const messages = [title]
  const refs = [titleRef]

  useTypewriterOnScroll(refs, messages, styles, 0) //500

  const descriptionRef = useRef(null)
  const revealRefs = [descriptionRef]
  const revealDelays = [500]
  const { revealedIndices } = useRevealOnScroll(revealRefs, revealDelays)

  const [evs, setEvs] = useState([])

  const controlsLeft = useAnimation()
  const controlsRight = useAnimation()

  /*useEffect(() => {
    if (!router.isReady) return;

    authenticatedFetchAPI('/api/random_vehicles')
      .then((data) => {
        //console.log("Fetched data:", data.data);
        setEvs(data.data);
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
      });
  }, [router.isReady]);*/

  useEffect(() => {
    if (!router.isReady) return

    const fetchVehicles = async () => {
      const apiUrl = `${process.env.NEXT_PUBLIC_CLEARWATT_BASE_API}/vehicles/random_vehicles/`
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        setEvs(data.results)
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error)
      }
    }

    fetchVehicles()
  }, [router.isReady])

  useEffect(() => {
    const handleResize = () => {
      // stop the existing animation
      controlsLeft.stop()
      controlsRight.stop()

      const container = document.querySelector(`.${styles.evList}`)
      const containerWidth = container ? container.clientWidth / 2 : 0

      controlsLeft.start({
        x: [0, -containerWidth],
        transition: {
          repeat: Infinity,
          //repeatType: "loop",
          repeatType: 'reverse',
          duration: 20,
          ease: 'linear',
        },
      })

      controlsRight.start({
        x: [-containerWidth, 0],
        transition: {
          repeat: Infinity,
          //repeatType: "loop",
          repeatType: 'reverse',
          duration: 20,
          ease: 'linear',
        },
      })
    }

    // Attach event listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Cleanup
      window.removeEventListener('resize', handleResize)
    }
  }, [controlsLeft, controlsRight])

  useEffect(() => {
    if (evs?.length === 0) {
      return // Skip if no EVs are set yet
    }

    const container = document.querySelector(`.${styles.evList}`)
    const containerWidth = container ? container.clientWidth / 2 : 0

    controlsLeft.start({
      x: [0, -containerWidth],
      transition: {
        repeat: Infinity,
        //repeatType: "loop",
        repeatType: 'reverse',
        duration: 20,
        ease: 'linear',
      },
    })

    controlsRight.start({
      x: [-containerWidth, 0],
      transition: {
        repeat: Infinity,
        //repeatType: "loop",
        repeatType: 'reverse',
        duration: 20,
        ease: 'linear',
      },
    })
  }, [evs])

  const handleSearch = (newSearchTerm) => {
    let newQuery = { ...router.query, page: 1 }

    if (newSearchTerm.model) {
      setSearchTerm({ term: newSearchTerm.model, type: 'model' })
      setModel(newSearchTerm.model)
      newQuery.model = newSearchTerm.model
      newQuery.make = newSearchTerm.make // Include make in the query when model is selected
      setBadges((prevBadges) => [
        ...prevBadges.filter((badge) => badge.filterIndex !== 'model'),
        { filterIndex: 'model', value: newSearchTerm.model },
        { filterIndex: 'make', value: newSearchTerm.make }, // Maintain make as badge
      ])
    } else if (newSearchTerm.make) {
      setSearchTerm({ term: newSearchTerm.make, type: 'make' })
      setModel(null)
      newQuery.make = newSearchTerm.make
      delete newQuery.model
      setBadges((prevBadges) => [
        ...prevBadges.filter((badge) => badge.filterIndex !== 'make'),
        { filterIndex: 'make', value: newSearchTerm.make },
      ])
    }

    router.push({
      pathname: '/directory',
      query: newQuery,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <div className={styles.col_2}>
            <div className={styles.leftDetail}></div>
            <motion.div className={styles.evList} animate={controlsLeft}>
              {evs.slice(0, 10).map((ev, index) => (
                <div key={`left-${index}`} className={styles.evCard}>
                  {ev.image ? (
                    <img className={styles.evCardImage} src={ev.image} alt={`${ev.make} ${ev.model}`} />
                  ) : (
                    <div className={styles.fallbackContainer}>
                      <Image
                        className={styles.evCardImageFallback}
                        src={'/illustrations/v2/EV-v1.png'}
                        alt={`${ev.make} ${ev.model}`}
                        width={800}
                        height={500}
                      />
                    </div>
                  )}
                  {ev.make} {ev.model}
                </div>
              ))}
            </motion.div>
            <motion.div className={styles.evList} animate={controlsRight}>
              {evs.slice(10, 20).map((ev, index) => (
                <div key={`right-${index}`} className={styles.evCard}>
                  {ev.image ? (
                    <img className={styles.evCardImage} src={ev.image} alt={`${ev.make} ${ev.model}`} />
                  ) : (
                    <div className={styles.fallbackContainer}>
                      <Image
                        className={styles.evCardImageFallback}
                        src={'/illustrations/v2/EV-v1.png'}
                        alt={`${ev.make} ${ev.model}`}
                        width={800}
                        height={500}
                      />
                    </div>
                  )}
                  {ev.make} {ev.model}
                </div>
              ))}
            </motion.div>
            <div className={styles.rightDetail}></div>
          </div>
          <div className={styles.col_1}>
            <SearchComponentXS onSearch={handleSearch} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EVExplorerPreview
