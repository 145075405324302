import styles from '@/components/PartnersContainer.module.css'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import useRevealOnScroll from '@/utils/useRevealOnScroll'

const parentVariants = {
  hidden: { opacity: 1 }, // parent is visible but children start hidden
  visible: {
    opacity: 1,
    transition: {
      // This will cause the children to fade in one after another
      staggerChildren: 0.3,
    },
  },
}

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
}

const PartnersContainer = () => {
  const messageRef2 = useRef(null)
  const descriptionRef = useRef(null)
  const title2 = 'Our Supporters'

  const messages = [title2]
  const refs = [messageRef2]
  const delays = [0, 0]

  const revealRefs = [descriptionRef]
  const revealDelays = [500]

  useTypewriterOnScroll(refs, messages, styles, delays)
  const { revealedIndices } = useRevealOnScroll(revealRefs, revealDelays)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.sectionIntroduction}>
          <h3 ref={messageRef2} className={styles.h3}></h3>

          <motion.div
            ref={descriptionRef}
            className={styles.description}
            initial={{ opacity: 0, y: 20 }}
            animate={revealedIndices.has(0) ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          ></motion.div>
        </div>
        <motion.div
          className={styles.sectionContent}
          variants={parentVariants}
          initial="hidden"
          animate={revealedIndices.has(0) ? 'visible' : 'hidden'}
        >
          <motion.div className={styles.secondColumn} variants={childVariants}>
            <div className={styles.cardPurple}>
              <p className={styles.mono}>Recommended by</p>
              <div className={styles.bottomLogo}>
                <img className={styles.ATlogo} alt="AutoTrader logo" src="/partners/autotrader.png" />
              </div>
            </div>

            <div className={styles.cardRed}>
              <p className={styles.mono}>Official data partner to</p>
              <div className={styles.bottomLogo}>
                <img className={styles.LElogo} alt="Love Electric logo" src="/partners/loveelectric.svg" />
              </div>
            </div>
          </motion.div>

          {/* First Column (Blue card) */}
          <motion.div className={styles.cardBlue} variants={childVariants}>
            <p className={styles.quote}>
              “Transparency is key for used EV buyers. ClearWatt reports ensure that as a dealer, you can provide
              accurate information on battery health, real range and other key data, allowing the customer to make
              informed purchasing decisions.”
            </p>
            <div className={styles.attribution}>
              <h4>Nick Raimo</h4>
              <p>Managing Director Luigi Motors</p>
              <p>
                YouTube:
                <a
                  href="https://www.youtube.com/c/NicolasRaimo"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }} // Optional for styling
                >
                  @EVNick
                </a>
              </p>
            </div>
          </motion.div>

          {/* Second Column */}
          <motion.div className={styles.secondColumn} variants={childVariants}>
            <div className={styles.cardGreen}>
              <div className={styles.logoGrid}>
                <img
                  className={styles.logo}
                  alt="Department for Transport logo"
                  src="/partners/Department_for_Transport.png"
                />
                <img className={styles.logo} alt="OZEV logo" src="/partners/OZEV_high-res-logo.png" />
              </div>
            </div>

            <div className={styles.cardDarkBlue}>
              <div className={styles.logoGrid}>
                <img className={styles.logo} alt="Motability logo" src="/partners/motability.png" />
                <img className={styles.logoOneAuto} alt="One Auto API logo" src="/partners/oneautoapi.png" />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default PartnersContainer
