import styles from '@/components/home/product-section/product-previews/UsedEVLookupPreview.module.css'
import { useState, useEffect } from 'react'

const UsedEVLookupPreview = () => {
  const [animateKey, setAnimateKey] = useState(Date.now()) // Unique key for animation reset

  useEffect(() => {
    // Update the key whenever the component is mounted or re-rendered
    setAnimateKey(Date.now())
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.report}>
        <img className={styles.img} src="/reports/L1.svg" alt="EV FactSheet" />
      </div>
    </div>
  )
}

export default UsedEVLookupPreview
