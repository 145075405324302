import { useRef, useState, useEffect } from 'react'
import styles from '@/components/home/v2/HeroSection.module.css'
import { FaArrowRight } from 'react-icons/fa'
import Link from 'next/link'
import Image from 'next/image'
import useRevealOnScroll from '@/utils/useRevealOnScroll'
import { motion } from 'framer-motion'
import LinkScroll from '@/components/LinkScroll'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'

function HeroSection() {
  const messageRef1 = useRef(null)
  const descriptionRef = useRef(null)

  const title_1 = 'Welcome to ClearWatt'

  // Define the segments with styled and unstyled parts
  const h1Segments = [
    { text: 'Helping ', styled: false },
    { text: 'ev', styled: true },
    { text: 'eryone understand ', styled: false },
    { text: 'ev', styled: true },
    { text: 'erything about ', styled: false },
    { text: 'e', styled: true },
    { text: 'lectric ', styled: false },
    { text: 'v', styled: true },
    { text: 'ehicles ', styled: false },
  ]

  // Flatten the segments into an array of characters with styling info
  const h1Characters = h1Segments.flatMap((segment) =>
    segment.text.split('').map((char) => ({ char, styled: segment.styled })),
  )

  const [currentIndex, setCurrentIndex] = useState(0)
  const typingSpeed = 20 // milliseconds per character

  const revealRefs = [descriptionRef]
  const revealDelays = [1000]

  const { revealedIndices } = useRevealOnScroll(revealRefs, revealDelays)

  // Start the typewriter effect for title_1 using the existing hook
  useTypewriterOnScroll([messageRef1], [title_1], styles, [500])

  // Manual typewriter effect for h1
  useEffect(() => {
    if (currentIndex < h1Characters.length) {
      const timer = setTimeout(() => {
        setCurrentIndex((prev) => prev + 1)
      }, typingSpeed)
      return () => clearTimeout(timer)
    }
  }, [currentIndex, h1Characters.length])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <div className={styles.col_1}>
            <h3 ref={messageRef1} className={styles.h3}></h3>

            {/* Manually added title_2 with custom styling and typewriter effect */}
            <h1 className={styles.h1}>
              {h1Characters.slice(0, currentIndex).map((item, index) => {
                if (item.styled) {
                  return (
                    <span key={index} className={styles.ev}>
                      {item.char}
                    </span>
                  )
                } else {
                  return <span key={index}>{item.char}</span>
                }
              })}
              {/* Blinking caret */}
              {currentIndex < h1Characters.length && <span className={styles.caret}>|</span>}
            </h1>

            <motion.div
              ref={descriptionRef}
              className={styles.description}
              initial={{ opacity: 0, y: 20 }}
              animate={revealedIndices.has(0) ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              ClearWatt supports buyers and sellers of EVs to make informed purchasing decisions. We provide industry
              leading EV performance reports to help you embrace the electric era with complete confidence.
            </motion.div>

            {/*<LinkScroll className={styles.CWButton} href="#beta-program">*/}
            {/*  <div className={styles.CWButtonLabel}>Validate the health of my EV</div>*/}
            {/*  <FaArrowRight className={styles.CWButtonIcon} size={24} />*/}
            {/*</LinkScroll>*/}
          </div>
          <div className={styles.col_2}>
            <Image
              src="/images/hero_image_clearwatt_02.jpg"
              alt="ClearWatt Hero Image"
              className={styles.heroImage}
              fill
              style={{ objectFit: 'cover' }}
              priority
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
