import React, { useRef } from 'react'
import useTypewriterOnScroll from '@/utils/useTypewriterOnScroll'
import Marquee from '@/components/ui/marquee/Marquee'
import styles from '@/components/home/v2/FeaturedSection.module.css'
import Image from 'next/image'
import useWindowWidth from '@/utils/useWindowWidth'

export default function FeaturedSection() {
  const messageRef1 = useRef(null)
  const width = useWindowWidth()

  const title_1 = 'As Featured In'
  const messages = [title_1]
  const refs = [messageRef1]
  const delays = [0]
  useTypewriterOnScroll(refs, messages, styles, delays)

  const speed = width <= 768 ? 60 : 20

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <h3 ref={messageRef1} className={styles.h3}></h3>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.gradientLeft}></div>
          <Marquee speed={speed}>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="AM Online logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/am-online_logo.svg"
                  width={201}
                  height={150}
                ></Image>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="Autocar logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/autocar_logo.svg"
                  width={201}
                  height={150}
                ></Image>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="Business Car logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/business-car_logo.png"
                  layout="intrinsic"
                  width={201}
                  height={150}
                ></Image>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="Financial Times logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/financial-times_logo.svg"
                  width={201}
                  height={150}
                ></Image>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="Fleet News logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/fleet-news_logo.svg"
                  width={201}
                  height={150}
                ></Image>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.illustration}>
                <Image
                  alt="Fully Charged logo"
                  className={styles.cwIllustration}
                  src="/illustrations/featured/fully-charged_logo.svg"
                  width={201}
                  height={60}
                ></Image>
              </div>
            </div>
          </Marquee>
          <div className={styles.gradientRight}></div>
        </div>
      </div>
    </div>
  )
}
