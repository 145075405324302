import { useRef, useState, useEffect } from "react";
import { motion, useMotionValue, useAnimationFrame } from "framer-motion";
import styles from "@/components/ui/marquee/Marquee.module.css";
export default function Marquee({
  speed = 80,
  children
}) {
  const marqueeRef = useRef(null);
  const [totalWidth, setTotalWidth] = useState(0);
  const x = useMotionValue(0);
  useEffect(() => {
    if (marqueeRef.current) {
      const scrollW = marqueeRef.current.scrollWidth;
      setTotalWidth(scrollW);
    }
  }, [children]);
  useAnimationFrame((t, delta) => {
    if (!totalWidth) return;
    const move = speed * delta / 1000;
    x.set(x.get() - move);
    const singleWidth = totalWidth / 2;
    if (x.get() <= -singleWidth) {
      x.set(0);
    }
  });
  return <div className={styles.marqueeWrapper} data-sentry-component="Marquee" data-sentry-source-file="Marquee.tsx">
            <motion.div className={styles.marqueeInner} ref={marqueeRef} style={{
      x
    }} data-sentry-element="unknown" data-sentry-source-file="Marquee.tsx">
                {children}
                {children}
            </motion.div>
        </div>;
}