import styles from '@/components/home/product-section/product-previews/EVHealthCheckerPreview.module.css'

const EVHealthCheckerPreview = () => {
  return (
    <div className={styles.container}>
      <div className={styles.report}>
        <img className={styles.img} src="/reports/L3.svg" alt="EV Health Report" />
      </div>
      <div className={styles.phone}>
        <img className={styles.phoneImg} src="/screens/vehicle-lookup.svg" alt="Vehicle lookup screen" />
      </div>
    </div>
  )
}

export default EVHealthCheckerPreview
