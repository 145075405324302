import styles from '@/components/home/product-section/product-previews/EVKnowledgeHubPreview.module.css'
import Link from 'next/link'
import { FaArrowRight } from 'react-icons/fa'

// Icons
import Umbrella from '@/components/svgs/icons/v2/Umbrella'
import Grants from '@/components/svgs/icons/v2/Grants'
import OutOfPower from '@/components/svgs/icons/v2/OutOfPower'
import TCO from '@/components/svgs/icons/v2/TCO'
import Leaf from '@/components/svgs/icons/v2/Leaf'
import KW from '@/components/svgs/icons/v2/KW'
import Parking from '@/components/svgs/icons/v2/Parking'
import Home from '@/components/svgs/icons/v2/Home'
import Connectors from '@/components/svgs/icons/v2/Connectors'
import ACDC from '@/components/svgs/icons/v2/ACDC'
import Range from '@/components/svgs/icons/v2/Range'
import Cog from '@/components/svgs/icons/v2/Cog'
import Buy from '@/components/svgs/icons/v2/Buy'
import Driving from '@/components/svgs/icons/v2/Driving'
import Glossary from '@/components/svgs/icons/v2/Glossary'
import Testing from '@/components/svgs/icons/v2/Testing'
import React from 'react'

const EVKnowledgeHubPreview = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftDetail}></div>
      <div className={styles.content}>
        <div className={styles.scrollAndDetailContainer}>
          <div className={styles.scrollContainer}>
            <div className={styles.contentRowCards}>
              <Link href="/knowledge/insurance" className={styles.topicCardCat1}>
                <div className={styles.cardIllustration}>
                  <Umbrella />
                </div>
                <div className={styles.cardTitle}>Insurance</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/grants-and-incentives" className={styles.topicCardCat1}>
                <div className={styles.cardIllustration}>
                  <Grants />
                </div>
                <div className={styles.cardTitle}>Grants/incentives</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/running-out-of-power" className={styles.topicCardCat1}>
                <div className={styles.cardIllustration}>
                  <OutOfPower />
                </div>
                <div className={styles.cardTitle}>Running out of power</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/tco" className={styles.topicCardCat1}>
                <div className={styles.cardIllustration}>
                  <TCO />
                </div>
                <div className={styles.cardTitle}>Total Cost of Ownership</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/are-evs-green" className={styles.topicCardCat1}>
                <div className={styles.cardIllustration}>
                  <Leaf />
                </div>
                <div className={styles.cardTitle}>Are EVs green?</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/kw-kwh" className={styles.topicCardCat2}>
                <div className={styles.cardIllustration}>
                  <KW />
                </div>
                <div className={styles.cardTitle}>kW vs kWh</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/parking" className={styles.topicCardCat2}>
                <div className={styles.cardIllustration}>
                  <Parking />
                </div>
                <div className={styles.cardTitle}>Parking</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/home-charging" className={styles.topicCardCat2}>
                <div className={styles.cardIllustration}>
                  <Home />
                </div>
                <div className={styles.cardTitle}>Home charging</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/connectors-tech-specs" className={styles.topicCardCat2}>
                <div className={styles.cardIllustration}>
                  <Connectors />
                </div>
                <div className={styles.cardTitle}>Connectors/tech specs</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/ac-dc-charging" className={styles.topicCardCat2}>
                <div className={styles.cardIllustration}>
                  <ACDC />
                </div>
                <div className={styles.cardTitle}>AC vs DC charging</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/understanding-range" className={styles.topicCardCat3}>
                <div className={styles.cardIllustration}>
                  <Range />
                </div>
                <div className={styles.cardTitle}>Understanding range</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/how-evs-work" className={styles.topicCardCat3}>
                <div className={styles.cardIllustration}>
                  <Cog />
                </div>
                <div className={styles.cardTitle}>How does an EV work?</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/getting-an-ev" className={styles.topicCardCat3}>
                <div className={styles.cardIllustration}>
                  <Buy />
                </div>
                <div className={styles.cardTitle}>Getting an EV</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/knowledge/experience-of-driving-an-ev" className={styles.topicCardCat3}>
                <div className={styles.cardIllustration}>
                  <Driving />
                </div>
                <div className={styles.cardTitle}>Experience of driving an EV</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/glossary" className={styles.topicCardCat4}>
                <div className={styles.cardIllustration}>
                  <Glossary />
                </div>
                <div className={styles.cardTitle}>Glossary</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>

              <Link href="/testing-guide" className={styles.topicCardCat4}>
                <div className={styles.cardIllustration}>
                  <Testing />
                </div>
                <div className={styles.cardTitle}>Testing Guide</div>
                <div className={styles.cardAction}>
                  <div className={styles.cardLabel}>Go</div>
                  <FaArrowRight className={styles.cardIcon} size={24} />
                </div>
              </Link>
            </div>
          </div>
          <div className={styles.rightDetail}></div>
        </div>
      </div>
    </div>
  )
}

export default EVKnowledgeHubPreview
