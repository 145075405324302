import React, { useRef, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { FiArrowUpRight } from 'react-icons/fi'

import Link from 'next/link'
import styles from '@/components/navhub/NavHub.module.css'
import data from '@/data/navhub.json'

import { motion } from 'framer-motion'
import { getLastPost, getAnnouncement } from '@/sanity/utils/sanity-utils'

import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { BiMenu, BiX } from 'react-icons/bi'
import NavCategory from '@/components/navhub/NavCategory'
import NavCategoryStatic from '@/components/navhub/NavCategoryStatic'

const NavHub = ({ theme = 'light' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [navData, setNavData] = useState([])
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)

  const [isPersisted, setIsPersisted] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  const [latestPost, setLatestPost] = useState(null)
  const [announcement, setAnnouncement] = useState(null)

  const navHubRef = useRef()
  const subMenuRef = useRef()

  const announcementSlug = 'beta-testing'

  const variants = {
    hidden: { opacity: 0, y: '5%' },
    visible: (i) => ({ opacity: 1, y: 0, transition: { duration: 0.5, delay: i * 0.2 } }),
  }

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  useEffect(() => {
    setNavData(data.elements)
  }, [])

  useEffect(() => {
    const bodyClasses = document.body.classList
    if (isOpen) {
      bodyClasses.add('modalOpen')
    } else {
      bodyClasses.remove('modalOpen')
    }
    return () => {
      bodyClasses.remove('modalOpen')
    }
  }, [isOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navHubRef.current &&
        !navHubRef.current.contains(event.target) &&
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target) &&
        isPersisted
      ) {
        setSelectedSubcategory(null)
        setIsPersisted(false)
        setActiveItem(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPersisted])

  useEffect(() => {
    // This function fetches the latest post and sets it into state
    const fetchLatestPost = async () => {
      const post = await getLastPost()
      setLatestPost(post)
    }

    const fetchAnnouncement = async () => {
      const announcement_ = await getAnnouncement(announcementSlug)
      setAnnouncement(announcement_)
    }

    // Call the function
    fetchLatestPost()
    fetchAnnouncement()
  }, []) // The empty array ensures that this effect runs only once, when the component mounts

  const renderNavItem = (item, index) => {
    // Check for "active" status. If not present or true, continue. If false, return null.
    if (item.hasOwnProperty('active') && !item.active) {
      return null
    }

    let className = ''
    if (item.type === 'section') {
      className = styles.section
    } else if (item.type === 'category') {
      className = styles.category
    } else {
      className = styles.subcategory
    }

    const renderCaret = () => {
      if (item.type === 'subcategory' && item.style === 'folder') {
        return selectedSubcategory === item.id ? <FaCaretUp /> : <FaCaretDown />
      }

      return null
    }

    const handleMouseEnter = () => {
      if (item.type === 'subcategory' && item.style === 'folder' && selectedSubcategory !== item.id) {
        setSelectedSubcategory(item.id)
        if (!isPersisted) {
          // only show the submenu when not persistent
          setIsPersisted(false)
        }
      }
    }

    const handleMouseLeave = () => {
      if (!isPersisted) {
        setSelectedSubcategory(null)
      }
    }

    const handleClick = () => {
      if (item.type === 'subcategory' && item.style === 'folder') {
        if (selectedSubcategory === item.id && isPersisted) {
          setIsPersisted(false)
          setSelectedSubcategory(null)
          setActiveItem(null)
        } else {
          setSelectedSubcategory(item.id)
          setIsPersisted(true)
          setActiveItem(item.id)
        }
      }

      // If the item has a URL and is either a 'subcategory' that isn't a 'folder', or a 'category', close the modal
      if (item.url && ((item.type === 'subcategory' && item.style !== 'folder') || item.type === 'category')) {
        setIsOpen(false)
      }
    }

    const variants = {
      hidden: { opacity: 0, y: '5%' },
      visible: (i) => ({ opacity: 1, y: 0, transition: { duration: 0.5, delay: i * 0.2 } }),
    }

    const element = (
      <motion.div
        key={index}
        className={`${className} ${activeItem === item.id ? styles.active : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        initial="hidden"
        animate="visible"
        variants={variants}
        custom={index}
      >
        {item.url && ((item.type === 'subcategory' && item.style !== 'folder') || item.type === 'category') ? (
          <Link href={item.url}>
            <div className={styles.headerElement}>
              <div className={styles.iconTitle}>
                {item.icon && <span>{item.icon}</span>}
                <div>{item.name}</div>
              </div>
              <div className={styles.caret}>{renderCaret()}</div>
            </div>
          </Link>
        ) : (
          <div className={styles.headerElement}>
            <div className={styles.iconTitle}>
              {item.icon && <span>{item.icon}</span>}
              <div>{item.name}</div>
            </div>
            <div className={styles.caret}>{renderCaret()}</div>
          </div>
        )}
        {item.type !== 'subcategory' &&
          navData.filter((i) => i.parent === item.id && (!i.active || i.active)).map(renderNavItem)}
        {selectedSubcategory === item.id && renderSubMenu()}
      </motion.div>
    )
    return element
  }

  const renderSubMenu = () => {
    if (selectedSubcategory) {
      const items = navData.filter((item) => item.parent === selectedSubcategory && item.type === 'article')

      const variants = {
        hidden: { opacity: 0, y: '10%' },
        visible: (i) => ({ opacity: 1, y: 0, transition: { duration: 0.5, delay: i * 0.2 } }),
      }

      return (
        <div className={styles.subMenu} ref={subMenuRef}>
          {items.map((item, index) => (
            <Link key={index} href={item.url}>
              <motion.div
                className={styles.subMenuItem}
                initial="hidden"
                animate="visible"
                variants={variants}
                custom={index}
              >
                {item.icon && <span>{item.icon}</span>}
                {item.name}
              </motion.div>
            </Link>
          ))}
        </div>
      )
    }

    return null
  }

  const renderSection = (sectionName) => {
    const items = navData.filter((item) => item.parent === null && item.name === sectionName)

    const variants = {
      hidden: { opacity: 0, y: '5%' },
      visible: (i) => ({ opacity: 1, y: 0, transition: { duration: 0.5, delay: i * 0.2 } }),
    }

    // Conditional check for sectionName
    return (
      <div className={sectionName === 'Knowledge' ? styles.categoryItems : ''}>
        {items.map(renderNavItem)}
        {sectionName === 'Latest Post' && latestPost && (
          // Render the latest post here
          <motion.div className={styles.post} initial="hidden" animate="visible" variants={variants}>
            <Link href={`/blog/${latestPost.slug}`}>
              <div className={styles.postCard}>
                <img className={styles.postImage} src={latestPost.image} alt={latestPost.title} />
                <div className={styles.postInfo}>
                  <div className={styles.title}>{latestPost.title}</div>
                  {/*<div className={styles.description}>{latestPost.description}</div>*/}
                </div>
              </div>
            </Link>
          </motion.div>
        )}
        {sectionName === 'Announcement' && announcement && (
          <motion.div className={styles.post} initial="hidden" animate="visible" variants={variants}>
            {announcement.url ? (
              <Link href={`${announcement.url}`}>
                <div className={styles.postInfo}>
                  <div className={styles.title}>{announcement.title}</div>
                  <div className={styles.description}>{announcement.description}</div>
                </div>
              </Link>
            ) : (
              <div className={styles.postInfo}>
                <div className={styles.title}>{announcement.title}</div>
                <div className={styles.description}>{announcement.description}</div>
              </div>
            )}
          </motion.div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.navbar} ref={navHubRef}>
      <button
        className={`${theme === 'dark' ? styles.menuBtnDark : styles.menuBtn}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <React.Fragment>
            <span>Close</span>
            <BiX size={24} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>Menu</span>
            <BiMenu size={24} />
          </React.Fragment>
        )}
      </button>

      {isOpen && (
        <div className={styles.modal}>
          {/*<div className={styles.ribbon}>*/}
          {/*  <div className={styles.ribbonText}>*/}
          {/*    <Link*/}
          {/*      className={styles.ribbonLink}*/}
          {/*      href="/ev-health-checker"*/}
          {/*      onClick={() => setIsOpen(false)}*/}
          {/*    >{`🎉 BETA testing now live!`}</Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <Link className={styles.cwLogo} href="/">
                <img className={styles.vectorIcon} alt="" src="/logo_white.svg" />
                {/*<span className={styles.cwBetaBadge}>BETA</span>*/}
              </Link>

              <button className={styles.menuBtnDark} onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                  <React.Fragment>
                    <span>Close</span>
                    <BiX size={24} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span>Menu</span>
                    <BiMenu size={24} />
                  </React.Fragment>
                )}
              </button>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.one_column}>
                <div className={styles.column}>
                  <motion.div className={styles.section} initial="hidden" animate="visible" variants={variants}>
                    <div className={styles.headerElement}>
                      <div className={styles.iconTitle}>
                        <div>For Consumers</div>
                      </div>
                    </div>
                    <div className={styles.tools}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={1}
                        className={styles.toolFull}
                      >
                        <Link href="/ev-health-checker" onClick={() => setIsOpen(false)}>
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>⚡️</div>
                            <div className={styles.toolTitle}>EV Health Checker App</div>
                          </div>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={1}
                        className={styles.toolHalf}
                      >
                        <Link href="/directory">
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>🚙</div>
                            <div className={styles.toolTitle}>EV Explorer</div>
                          </div>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={2}
                        className={styles.toolHalf}
                      >
                        <Link href="/labs">
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>🧪</div>
                            <div className={styles.toolTitle}>Labs</div>
                          </div>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={1}
                        className={styles.toolFull}
                      >
                        <Link href="/knowledge" onClick={() => setIsOpen(false)}>
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>📚</div>
                            <div className={styles.toolTitle}>EV Knowledge Hub</div>
                            <div className={styles.knowledgeHubContainer}>
                              <div className={styles.knowledgeHubRow}>
                                <div className={styles.knowledgeHubCategory}>
                                  Going Electric{' '}
                                  <span className={styles.moreIcon}>
                                    <FiArrowUpRight />
                                  </span>
                                </div>
                                <div className={styles.knowledgeHubCategory}>
                                  Charging{' '}
                                  <span className={styles.moreIcon}>
                                    <FiArrowUpRight />
                                  </span>
                                </div>
                              </div>
                              <div className={styles.knowledgeHubRow}>
                                <div className={styles.knowledgeHubCategory}>
                                  Car{' '}
                                  <span className={styles.moreIcon}>
                                    <FiArrowUpRight />
                                  </span>
                                </div>
                                <div className={styles.knowledgeHubCategory}>
                                  Community{' '}
                                  <span className={styles.moreIcon}>
                                    <FiArrowUpRight />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </motion.div>
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.one_column}>
                <div className={styles.column}>
                  <motion.div className={styles.section} initial="hidden" animate="visible" variants={variants}>
                    <div className={styles.headerElement}>
                      <div className={styles.iconTitle}>
                        <div>For Businesses</div>
                      </div>
                    </div>
                    <div className={styles.tools}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={1}
                        className={styles.toolFull}
                      >
                        <Link href="/lite" onClick={() => setIsOpen(false)}>
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>📋</div>
                            <div className={styles.toolTitle}>EV Resale Reports</div>
                          </div>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={2}
                        className={styles.toolFull}
                      >
                        <Link href="https://form.typeform.com/to/NDO6y50K" onClick={() => setIsOpen(false)}>
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>🔋</div>
                            <div className={styles.toolTitle}>EV Battery Health Checks</div>
                            <div className={styles.knowledgeHubContainer}>
                              <div className={styles.knowledgeHubRow}>
                                <div className={styles.knowledgeHubCategory}>Physical</div>
                                <div className={styles.knowledgeHubCategory}>Remote</div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </motion.div>

                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        custom={1}
                        className={styles.toolFull}
                      >
                        <Link href="https://form.typeform.com/to/NDO6y50K" onClick={() => setIsOpen(false)}>
                          <div className={styles.tool}>
                            <div className={styles.toolIcon}>👾</div>
                            <div className={styles.toolTitle}>EV API Data</div>
                          </div>
                        </Link>
                      </motion.div>
                    </div>
                  </motion.div>
                  <motion.div initial="hidden" animate="visible" variants={variants} custom={2}>
                    <div className={styles.column}>{renderSection('Announcement')}</div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.one_column}>
                <div className={styles.column}>{renderSection('Navigation')}</div>
                <motion.div initial="hidden" animate="visible" variants={variants} custom={2}>
                  <div className={styles.column}>{renderSection('Latest Post')}</div>
                </motion.div>
              </div>
            </div>
            <div className={styles.modalFooter}></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavHub
