import React from 'react'
import styles from '@/components/ui/buttons/ButtonComponent.module.css'

function ButtonComponent({ text, icon, iconPosition = 'left', hideIcon, onClick, variant = 'default', className }) {
  // Combine base button, variant class, and any custom class passed
  const buttonClass = `${styles.button} ${styles[variant] || ''} ${className || ''}`

  return (
    <button className={buttonClass} onClick={onClick}>
      {!hideIcon && icon && iconPosition === 'left' && <span className={styles.icon_left}>{icon}</span>}
      {text}
      {!hideIcon && icon && iconPosition === 'right' && <span className={styles.icon_right}>{icon}</span>}
    </button>
  )
}

export default ButtonComponent
