// pages/newIndex.js
import styles from '@/styles/HomeV2.module.css'
import Head from 'next/head'
import Header from '@/components/Header'
import Footer from '@/components/home/v2/Footer'
import HeroSection from '@/components/home/v2/HeroSection'
import AboutSection from '@/components/home/v2/AboutSection'
import KnowledgeSection from '@/components/home/v2/KnowledgeSection'
import ProductSection from '@/components/home/product-section/ProductSection'
import B2BSection from '@/components/home/v2/B2BSection'
import BetaFormSection from '@/components/home/v2/BetaFormSection'
import EVHCSection from '@/components/home/v2/EVHCSection'
import DirectorySection from '@/components/home/v2/DirectorySection'
import PartnersContainer from '@/components/PartnersContainer'
import FeaturedSection from "@/components/home/v2/FeaturedSection";

function NewHomePage() {
  return (
    <div>
      <Head>
        <title>ClearWatt: The Second-Hand Electric Revolution</title>
        <meta
          name="description"
          content="ClearWatt makes buying, selling, and owning electric vehicles simple and hassle-free. Discover tools, resources, and support to confidently embrace the electric era."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="robots" content="index, follow" />

        <link rel="icon" href="/favicon.png" />
        <link rel="canonical" href="https://clearwatt.co.uk/" />

        {/* Open Graph Metadata */}
        <meta property="og:title" content="ClearWatt: The Second-Hand Electric Revolution" />
        <meta
          property="og:description"
          content="Join ClearWatt to explore tools, resources, and certification for electric vehicles. Simplify buying, selling, and owning EVs with confidence."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://clearwatt.co.uk/" />
        <meta property="og:image" content="https://clearwatt.co.uk/home-thumbnail.jpg" />

        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ClearWatt: The Second-Hand Electric Revolution" />
        <meta
          name="twitter:description"
          content="ClearWatt makes the shift to EVs simple and accessible. Discover EV directories, health certification, and a wealth of knowledge for consumers and businesses."
        />
        <meta name="twitter:image" content="https://clearwatt.co.uk/home-thumbnail.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'ClearWatt',
            url: 'https://clearwatt.co.uk/',
            description:
              'ClearWatt simplifies the buying, selling, and owning of electric vehicles through tools, certification, and a wealth of knowledge.',
            publisher: {
              '@type': 'Organization',
              name: 'ClearWatt',
              logo: {
                '@type': 'ImageObject',
                url: 'https://clearwatt.co.uk/logo.png',
              },
            },
          })}
        </script>
      </Head>

      <Header />
      <HeroSection />
      <FeaturedSection />
      <ProductSection />
      <PartnersContainer />
      <AboutSection />
      <Footer />
    </div>
  )
}

export default NewHomePage
