import React from 'react'
import styles from './statusIndicator.module.css'

const StatusIndicator = ({ status, size = '72px' }) => {
  let backgroundColor

  switch (status) {
    case 'green':
      backgroundColor = '#00FF00'
      break
    case 'grey':
      backgroundColor = '#CCCCCC'
      break
    default:
      backgroundColor = '#FFFFFF'
      break
  }

  const style = {
    backgroundColor,
    width: size,
    height: size,
  }

  return <div className={styles.indicator} style={style}></div>
}

export default StatusIndicator
