import { useState, useEffect } from 'react'
import styles from '@/components/home/product-section/product-previews/EVAPIPreview.module.css'

const EVAPIDataPreview = () => {
  const [animateKey, setAnimateKey] = useState(Date.now()) // Unique key for animation reset

  useEffect(() => {
    // Update the key whenever the component is mounted or re-rendered
    setAnimateKey(Date.now())
  }, [])

  return (
    <div className={styles.container}>
      <div key={animateKey} /* Reset the animation by changing the key */ className={styles.report}>
        <img className={styles.img} src="/screens/api-preview.svg" alt="API Preview" />
      </div>
    </div>
  )
}

export default EVAPIDataPreview
